import { Card } from '@allurion/ui';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { TrackedSortByMenu } from 'src/analytics/TrackedUI';
import { Article, ArticleCollection } from 'src/domain/patient/Patients';
import { useArticleCollections } from 'src/hooks/useArticleCollections';
import { usePatientContentSubscription } from 'src/hooks/useContentSubscription';
import { usePatientArticles } from 'src/hooks/usePatientArticles';
import { useThrottledWindowSize } from 'src/hooks/useWindowSize';
import { breakpoints } from 'src/styles/theme/breakpoints';
import { useTableSort } from 'src/ui/useTableSort';

import { ContentArticlesCards } from './ContentArticlesCards';
import { ContentArticlesTable } from './ContentArticlesTable';
import { ContentSubscription } from './ContentSubscription';

import styles from './PatientContentLibrary.module.scss';

type Props = {
  patientId: string;
};

export function PatientContentLibrary({ patientId }: Props) {
  const { articleCollections } = useArticleCollections();
  const { articles, reload, isLoading } = usePatientArticles(patientId);
  const {
    subscriptions,
    reload: reloadSubscriptions,
    isLoading: isLoadingSubscriptions,
  } = usePatientContentSubscription(patientId);

  const articlesByCollection = useMemo(() => {
    const articlesByCollection = articles.reduce(
      (map, article) => ({
        ...map,
        [article.collectionId]: [...(map[article.collectionId] || []), article],
      }),
      {} as Record<string, typeof articles>
    );

    return articlesByCollection;
  }, [articles]);

  return (
    <div className={styles.container}>
      {articleCollections.map((collection) => (
        <ArticleCollectionCard
          key={collection.id}
          collection={collection}
          patientId={patientId}
          articles={articlesByCollection[collection.id] ?? []}
          isLoading={isLoading}
          isSubscribed={subscriptions.some(
            (s) => s.article_collection_id === collection.id && s.is_active
          )}
          reload={reload}
          reloadSubscriptions={reloadSubscriptions}
          isLoadingSubscriptions={isLoadingSubscriptions}
        />
      ))}
    </div>
  );
}

function ArticleCollectionCard({
  collection,
  patientId,
  articles,
  isLoading,
  reload,
  isSubscribed,
  reloadSubscriptions,
  isLoadingSubscriptions,
}: {
  patientId: string;
  articles: Article[];
  collection: ArticleCollection;
  isLoading: boolean;
  reload: () => Promise<any>;
  isSubscribed: boolean;
  reloadSubscriptions: () => Promise<any>;
  isLoadingSubscriptions: boolean;
}) {
  const [width] = useThrottledWindowSize();
  const isMobile = width < breakpoints.mobile;
  const intl = useIntl();

  const {
    sortedData: sortedArticles,
    sortBy,
    setSortBy,
  } = useTableSort<Article>(articles, '-notificationDate', {
    notificationDate: 'string',
    sentDate: 'string',
    viewDate: 'string',
  });

  const sortingOptions = [
    {
      label: intl.formatMessage({
        id: 'content-library.articles-table.title',
        defaultMessage: 'Title',
      }),
      value: 'title',
    },
    isSubscribed && {
      label: intl.formatMessage({
        id: 'content-library.articles-table.scheduled',
        defaultMessage: 'Scheduled',
      }),
      value: 'notificationDate',
    },
    {
      label: intl.formatMessage({
        id: 'content-library.articles-table.sent',
        defaultMessage: 'Sent',
      }),
      value: 'sentDate',
    },
    {
      label: intl.formatMessage({
        id: 'content-library.articles-table.viewed',
        defaultMessage: 'Viewed',
      }),
      value: 'viewDate',
    },
  ].filter(Boolean) as { label: string; value: string }[];

  return (
    <Card
      title={
        <div className={styles.actions}>
          <div>{collection.title}</div>
          <div className={styles.sortByMenu}>
            <TrackedSortByMenu
              trackLabel="patient-library-sort-by"
              selectedOption={sortBy}
              options={sortingOptions}
              tooltipText={intl.formatMessage({
                id: 'content-library.articles-table.sort-by',
                defaultMessage: 'Sort articles',
              })}
              onChange={setSortBy}
              size="xs"
              variant="secondary"
            />
          </div>
        </div>
      }
      key={collection.id}
    >
      <ContentSubscription
        patientId={patientId}
        collectionId={collection.id}
        isSubscribed={isSubscribed}
        reload={reloadSubscriptions}
        isLoading={isLoadingSubscriptions}
      />

      <div className={styles.content}>
        {isMobile ? (
          <ContentArticlesCards
            patientId={patientId}
            articles={sortedArticles}
            isLoading={isLoading}
            reload={reload}
            isSubscribed={isSubscribed}
          />
        ) : (
          <ContentArticlesTable
            patientId={patientId}
            articles={sortedArticles}
            isLoading={isLoading}
            reload={reload}
            sortBy={sortBy}
            setSortBy={setSortBy}
            isSubscribed={isSubscribed}
          />
        )}
      </div>
    </Card>
  );
}
