import { isClinicLedOnboardingEnabled } from '@allurion/domain';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { TrackedPageHeader } from 'src/analytics/TrackedUI';
import { Seo } from 'src/components/Seo';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';

import { ClinicPatients } from './ClinicPatients';

import styles from './ClinicPatientsPage.module.scss';

export function ClinicPatientsPage() {
  const { clinicId } = useParams();
  const intl = useIntl();
  const { toInvitePatientPage: toAddPatientPage } = useAppNavigate();
  const { settings } = useClinicSettings(clinicId);
  const { isAdmin } = useCurrentProvider();

  const showAddPatientBtn = isAdmin && isClinicLedOnboardingEnabled(settings);

  if (!clinicId) {
    throw new Error('Invalid clinic');
  }

  return (
    <>
      <Seo title="Clinic Patients" />

      <div className={styles.clinicPageContainer}>
        <TrackedPageHeader
          title={intl.formatMessage({
            id: 'clinic-patients',
            description: 'Clinic Patients',
            defaultMessage: 'Active Patients',
          })}
          button={
            showAddPatientBtn
              ? {
                  label: intl.formatMessage({
                    id: 'invite-patient-button',
                    defaultMessage: 'Add new patient',
                  }),
                  size: 'sm',
                  onClick() {
                    toAddPatientPage(clinicId);
                  },
                  trackLabel: 'invite-new-patient',
                }
              : undefined
          }
        />
      </div>

      <ClinicPatients clinicId={clinicId} key={`ClinicActivePatients-${clinicId}`} />
    </>
  );
}
