import styles from './IconButton.module.scss';

export type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  /** How the button style should be? */
  variant?:
    | 'primary'
    | 'secondary'
    | 'outlined'
    | 'icon'
    | 'primaryDark'
    | 'outlinedDark'
    | 'iconDark'
    | 'danger'
    | 'secondaryDanger';

  /** Disable the button */
  disabled?: boolean;
  /** How large should the button be? */
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
  /** Left icon component */
  icon: React.ReactNode | null;
  /**  Is button active */
  active?: boolean;
};

export function IconButton({
  variant = 'primary',
  disabled = false,
  size = 'md',
  icon,
  className,
  active,
  ...props
}: IconButtonProps) {
  const icnBtnColor = variant.charAt(0).toUpperCase() + variant.slice(1);
  const icnBtnSize = size.charAt(0).toUpperCase() + size.slice(1);

  return (
    <button
      disabled={disabled}
      type="button"
      className={[
        styles['icnBtn'],
        styles[`icnBtn${icnBtnSize}`],
        styles[`icnBtn${icnBtnColor}`],
        active ? styles[`icnBtn${icnBtnColor}__active`] : '',
        className,
      ]
        .filter(Boolean)
        .join(' ')}
      {...props}
    >
      {icon}
    </button>
  );
}
