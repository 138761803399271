import { isNotNull } from '@allurion/utils';
import { AtRiskDisplay } from './risk';

export type PatientWithStats = {
  active_patient: boolean;
  age: number;
  current_bmi: number;
  current_weight: number;
  daily_weigh_rate: number;
  days_in_program: number;
  hospitalid: number;
  lastname: string;
  latest_treatment_id: string;
  latest_treatment_category_id?: string;
  latest_treatment_start_date: string;
  monthly_weigh_rate: number;
  name: string;
  perc_weight_loss_mo1: number;
  perc_weight_loss_mo2: number;
  perc_weight_loss_mo3: number;
  perc_weight_loss_mo4: number;
  perc_weight_loss_mo5: number;
  perc_weight_loss_mo6: number;
  perc_weight_loss_since_placement: number;
  starting_bmi: number;
  starting_weight: number;
  uid: number;
  userid: null;
  uuid: string;
  weekly_weigh_rate: number;
  weeks_in_program: number;
  weeks_weighed_in: number;
  weight_loss_since_placement: number;
  within_7: boolean;
  within_14: boolean;
  at_risk_score: number;
  at_risk_display: AtRiskDisplay;
  last_weigh_in: string;
};

export function hoistPatientsWithNoTreatments(patients: PatientWithStats[]) {
  const patientsWithNoTreatments = patients.filter((patient) => !patient.latest_treatment_id);
  const patientsWithTreatments = patients.filter((patient) => patient.latest_treatment_id);

  return [...patientsWithNoTreatments, ...patientsWithTreatments];
}

export function didInstalledMobileApp(patient: { uuid: string | null }) {
  return isNotNull(patient.uuid);
}
