import { defineMessages } from 'react-intl';

export default defineMessages({
  treatmentType1: {
    id: 'treatment_type_1',
    defaultMessage: 'Allurion Balloon Program',
  },
  treatmentType2: {
    id: 'treatment_type_2',
    defaultMessage: 'Non-Allurion Balloon',
  },
  treatmentType3: {
    id: 'treatment_type_3',
    defaultMessage: 'Bariatric Surgery',
  },
  treatmentType4: {
    id: 'treatment_type_4',
    defaultMessage: 'Weight-loss medication',
  },
  treatmentType5: {
    id: 'treatment_type_5',
    defaultMessage: 'Other weight-loss program',
  },
  treatmentTypeCustom1Long: {
    id: 'treatment_type_custom_1_long',
    defaultMessage: 'Allurion Balloon Program',
  },
  treatmentType100: {
    id: 'treatment_type_custom_2_long',
    defaultMessage: 'Weight Doctors BARIATRICS® Gastric Bypass',
  },
  treatmentType100Short: {
    id: 'treatment_type_custom_2_short',
    defaultMessage: 'Gastric Bypass',
  },
  treatmentType101: {
    id: 'treatment_type_custom_3_long',
    defaultMessage: 'Weight Doctors BARIATRICS® Gastric Sleeve',
  },
  treatmentType101Short: {
    id: 'treatment_type_custom_3_short',
    defaultMessage: 'Gastric Sleeve',
  },
  treatmentType102: {
    id: 'treatment_type_custom_4_long',
    defaultMessage: 'Weight Doctors SLEEVE® OverStitch',
  },
  treatmentType102Short: {
    id: 'treatment_type_custom_4_short',
    defaultMessage: 'WD Sleeve OverStitch',
  },
  treatmentType103: {
    id: 'treatment_type_custom_5_long',
    defaultMessage: 'Weight Doctors SLEEVE® pose2',
  },
  treatmentType103Short: {
    id: 'treatment_type_custom_5_short',
    defaultMessage: 'WD Sleeve pose2',
  },
  treatmentType104: {
    id: 'treatment_type_custom_6_long',
    defaultMessage: 'Gastric Balloon for 12 months',
  },
  treatmentType104Short: {
    id: 'treatment_type_custom_6_short',
    defaultMessage: '12-month Balloon',
  },
  treatmentType105: {
    id: 'treatment_type_custom_7_long',
    defaultMessage: 'Gastric Balloon for 6 months',
  },
  treatmentType105Short: {
    id: 'treatment_type_custom_7_short',
    defaultMessage: '6-month Balloon',
  },
  treatmentType106: {
    id: 'treatment_type_custom_8_long',
    defaultMessage: 'Medication Therapy',
  },
  treatmentTypeCustom6Short: {
    id: 'treatment_type_custom_8_short',
    defaultMessage: 'Meds Therapy',
  },
  addTreatment: {
    id: 'treatment-list.add-treatment',
    defaultMessage: 'Add Treatment',
  },
  editTreatmentDialogTitle: {
    id: 'treatment-list.edit-treatment.dialog-title',
    defaultMessage: 'Edit Treatment',
  },
  treatmentType: {
    id: 'treatment-list.treatment-type',
    defaultMessage: 'Treatment Type',
  },
  treatmentStartDate: {
    id: 'treatment-list.start-date',
    defaultMessage: 'Program Start Date',
  },
  treatmentEndDate: {
    id: 'treatment-list.end-date',
    defaultMessage: 'Program End Date',
  },
  treatmentSave: {
    id: 'treatment-list.save',
    defaultMessage: 'Save',
  },
  treatmentDelete: {
    id: 'treatment-list.delete',
    defaultMessage: 'Are you sure you want to delete this treatment?',
  },
  treatmentDeleteTooltip: {
    id: 'treatment-list.delete-tooltip',
    defaultMessage: 'Delete treatment',
  },
  treatmentEditTooltip: {
    id: 'treatment-list.edit-tooltip',
    defaultMessage: 'Edit treatment',
  },
  treatmentAddTooltip: {
    id: 'treatment-list.add-tooltip',
    defaultMessage: 'Add treatment',
  },
  startDateErrorMessage: {
    id: 'add-treatment.date-out-of-range',
    defaultMessage: 'Start date must be within 90 days of the current date.',
  },
  endDateErrorMessage: {
    id: 'treatment-list.end-date-error',
    defaultMessage: 'End date cannot be earlier than start date',
  },
  treatmentTypePlaceholder: {
    id: 'treatment-list.treatment-type-placeholder',
    defaultMessage: 'Select treatment type',
  },
  treatmentCategoryName1: {
    id: 'treatment-category-name-1',
    defaultMessage: 'Allurion Balloon Program',
  },
  treatmentCategoryName2: {
    id: 'treatment-category-name-2',
    defaultMessage: 'Endoscopic Balloon',
  },
  treatmentCategoryName3: {
    id: 'treatment-category-name-3',
    defaultMessage: 'Bariatric Surgery',
  },
  treatmentCategoryName4: {
    id: 'treatment-category-name-4',
    defaultMessage: 'Anti-Obesity Medication',
  },
  treatmentCategoryName5: {
    id: 'treatment-category-name-5',
    defaultMessage: 'Lifestyle Program',
  },
  treatmentCategoryShortName1: {
    id: 'treatment-category-short-name-1',
    defaultMessage: 'Allurion Balloon',
  },
  treatmentCategoryShortName2: {
    id: 'treatment-category-short-name-2',
    defaultMessage: 'Endoscopic Balloon',
  },
  treatmentCategoryShortName3: {
    id: 'treatment-category-short-name-3',
    defaultMessage: 'Surgery',
  },
  treatmentCategoryShortName4: {
    id: 'treatment-category-short-name-4',
    defaultMessage: 'Medication',
  },
  treatmentCategoryShortName5: {
    id: 'treatment-category-short-name-5',
    defaultMessage: 'Lifestyle',
  },
  treatmentName1: {
    id: 'treatment-name-1',
    defaultMessage: 'First Allurion Balloon',
  },
  treatmentName2: {
    id: 'treatment-name-2',
    defaultMessage: 'Second Consecutive Allurion Balloon',
  },
  treatmentName3: {
    id: 'treatment-name-3',
    defaultMessage: 'Obera® 6 Month Balloon',
  },
  treatmentName4: {
    id: 'treatment-name-4',
    defaultMessage: 'Obera® 12 Month Balloon',
  },
  treatmentName5: {
    id: 'treatment-name-5',
    defaultMessage: 'SPATZ3 Adjustable Balloon',
  },
  treatmentName6: {
    id: 'treatment-name-6',
    defaultMessage: 'Other Endoscopic Balloon',
  },
  treatmentName7: {
    id: 'treatment-name-7',
    defaultMessage: 'Sleeve Gastrectomy',
  },
  treatmentName8: {
    id: 'treatment-name-8',
    defaultMessage: 'Gastric Bypass',
  },
  treatmentName9: {
    id: 'treatment-name-9',
    defaultMessage: 'Endoscopic Surgery',
  },
  treatmentName10: {
    id: 'treatment-name-10',
    defaultMessage: 'Other Surgery',
  },
  treatmentName11: {
    id: 'treatment-name-11',
    defaultMessage: 'Liraglutide',
  },
  treatmentName12: {
    id: 'treatment-name-12',
    defaultMessage: 'Semaglutide',
  },
  treatmentName13: {
    id: 'treatment-name-13',
    defaultMessage: 'Tirzepatide',
  },
  treatmentName14: {
    id: 'treatment-name-14',
    defaultMessage: 'Phentermine/Topiramate',
  },
  treatmentName15: {
    id: 'treatment-name-15',
    defaultMessage: 'Naltrexone/Bupropion',
  },
  treatmentName16: {
    id: 'treatment-name-16',
    defaultMessage: 'Other Medication',
  },
  treatmentName17: {
    id: 'treatment-name-17',
    defaultMessage: 'Lifestyle Program',
  },
  treatmentShortName1: {
    id: 'treatment-short-name-1',
    defaultMessage: 'First',
  },
  treatmentShortName2: {
    id: 'treatment-short-name-2',
    defaultMessage: 'Consecutive',
  },
  treatmentShortName3: {
    id: 'treatment-short-name-3',
    defaultMessage: 'Obera® 6 Month',
  },
  treatmentShortName4: {
    id: 'treatment-short-name-4',
    defaultMessage: 'Obera® 12 Month',
  },
  treatmentShortName5: {
    id: 'treatment-short-name-5',
    defaultMessage: 'SPATZ3',
  },
  treatmentShortName6: {
    id: 'treatment-short-name-6',
    defaultMessage: 'Other',
  },
  treatmentShortName7: {
    id: 'treatment-short-name-7',
    defaultMessage: 'Sleeve',
  },
  treatmentShortName8: {
    id: 'treatment-short-name-8',
    defaultMessage: 'Bypass',
  },
  treatmentShortName9: {
    id: 'treatment-short-name-9',
    defaultMessage: 'Endoscopic',
  },
  treatmentShortName10: {
    id: 'treatment-short-name-10',
    defaultMessage: 'Other',
  },
  treatmentShortName11: {
    id: 'treatment-short-name-11',
    defaultMessage: 'Liraglutide',
  },
  treatmentShortName12: {
    id: 'treatment-short-name-12',
    defaultMessage: 'Semaglutide',
  },
  treatmentShortName13: {
    id: 'treatment-short-name-13',
    defaultMessage: 'Tirzepatide',
  },
  treatmentShortName14: {
    id: 'treatment-short-name-14',
    defaultMessage: 'Phentermine/Topiramate',
  },
  treatmentShortName15: {
    id: 'treatment-short-name-15',
    defaultMessage: 'Naltrexone/Bupropion',
  },
  treatmentShortName16: {
    id: 'treatment-short-name-16',
    defaultMessage: 'Other',
  },
  treatmentShortName17: {
    id: 'treatment-short-name-17',
    defaultMessage: 'Lifestyle',
  },
  treatmentStartDate1: {
    id: 'treatment-category-start-date-1',
    defaultMessage: 'Date of balloon placement',
  },
  treatmentStartDate2: {
    id: 'treatment-category-start-date-2',
    defaultMessage: 'Date of balloon placement',
  },
  treatmentStartDate3: {
    id: 'treatment-category-start-date-3',
    defaultMessage: 'Date of surgical procedure',
  },
  treatmentStartDate4: {
    id: 'treatment-category-start-date-4',
    defaultMessage: 'Date of first dose',
  },
  treatmentStartDate5: {
    id: 'treatment-category-start-date-5',
    defaultMessage: 'Program start date',
  },
});
