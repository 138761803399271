import {
  didInstalledMobileApp,
  hoistPatientsWithNoTreatments,
  isRiskAnalyisEnabled,
  PatientWithStats,
} from '@allurion/domain';
import { isString } from '@allurion/utils';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { Pagination } from 'src/components/Pagination';
import { useClinicActivePatients } from 'src/hooks/useClinicActivePatients';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useThrottledWindowSize } from 'src/hooks/useWindowSize';
import { breakpoints } from 'src/styles/theme/breakpoints';
import { useTableSort } from 'src/ui/useTableSort';

import { ActivePatientsCards } from './ActivePatientsCards';
import { ActivePatientsTable } from './ActivePatientsTable';
import { ClinicPatientsActions } from './ClinicPatientActionts';
import { ClinicPatientsEmptyState } from './ClinicPatientsEmptyState';
import { PatientFilter, PatientStatusFilter, PatientViewFilter } from './ClinicPatientsFilter';

import styles from './ClinicPatients.module.scss';

type Props = {
  clinicId: string;
};

export function ClinicPatients({ clinicId }: Props) {
  const intl = useIntl();
  const [search, setSearch] = useState<string>('');
  const [width] = useThrottledWindowSize();
  const { settings } = useClinicSettings(clinicId);
  const [searchParams, setSearchParams] = useSearchParams();
  const statusFilter = (searchParams.get('status') as PatientStatusFilter) ?? 'active';
  const treatmentFilter = searchParams.get('treatment') ?? '';
  const providerFilter = searchParams.get('provider') ?? '';
  const viewFilter = (searchParams.get('view') ?? 'my') as PatientViewFilter;
  const { isLoading, activePatients } = useClinicActivePatients(clinicId, {
    showAll: viewFilter === 'all',
    provider: providerFilter,
  });

  const isMobile = width < breakpoints.mobile;

  const filteredPatients = useMemo(() => {
    return activePatients.filter((patient) => {
      let meetsFilter = false;

      switch (statusFilter) {
        case 'active':
          if (
            patient.active_patient &&
            didInstalledMobileApp(patient) &&
            patient.latest_treatment_id
          ) {
            meetsFilter = true;
          }
          break;

        case 'inactive':
          if (!patient.active_patient && didInstalledMobileApp(patient)) {
            meetsFilter = true;
          }
          break;

        case 'invited':
          if (!didInstalledMobileApp(patient)) {
            meetsFilter = true;
          }
          break;

        case 'incomplete':
          if (!patient.latest_treatment_id) {
            meetsFilter = true;
          }
          break;

        default:
          meetsFilter = true;
          break;
      }

      if (treatmentFilter) {
        meetsFilter = meetsFilter && patient.latest_treatment_id === treatmentFilter;
      }

      return (
        meetsFilter &&
        (patient.name?.toLowerCase().includes(search?.toLowerCase() ?? '') ||
          patient.lastname?.toLowerCase().includes(search?.toLowerCase() ?? ''))
      );
    });
  }, [activePatients, search, statusFilter, treatmentFilter]);

  const { sortedData, sortBy, setSortBy } = useTableSort<PatientWithStats>(
    filteredPatients,
    '-latest_treatment_start_date',
    {
      perc_weight_loss_since_placement: 'number',
      weight_loss_since_placement: 'number',
      current_weight: 'number',
      age: 'number',
      at_risk_score: 'number',
      current_bmi: 'number',
      latest_treatment_id: 'number',
      latest_treatment_category_id: 'number',
    }
  );

  const sortedPatients = hoistPatientsWithNoTreatments(sortedData);

  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const paginatedPatients = sortedPatients.slice((page - 1) * pageSize, page * pageSize);
  const lastPage = Math.ceil(sortedPatients.length / pageSize);

  const atRiskEnabled = isRiskAnalyisEnabled(settings);

  const noAvailablePatients = activePatients.length === 0 && !isLoading;
  const emptySearch = search.length > 0 && filteredPatients.length === 0;
  const showEmptyState = noAvailablePatients || emptySearch;

  return (
    <div className={styles.container}>
      <ClinicPatientsActions
        isLoading={isLoading}
        clinicId={clinicId}
        noAvailablePatients={noAvailablePatients}
        showEmptyState={showEmptyState}
        sortBy={sortBy}
        setSortBy={setSortBy}
        page={page}
        setPage={setPage}
        onSearch={onSearch}
        activeFilter={{
          status: statusFilter,
          treatment: treatmentFilter,
          view: viewFilter,
          provider: providerFilter,
        }}
        onFilterChange={updateFilters}
      />

      {showEmptyState ? (
        <ClinicPatientsEmptyState
          noAvailablePatients={noAvailablePatients}
          emptySearch={emptySearch}
          settings={settings}
          clinicId={clinicId}
        />
      ) : (
        <>
          {isMobile ? (
            <ActivePatientsCards
              data={paginatedPatients}
              isAtRiskEnabled={atRiskEnabled}
              clinicId={clinicId}
            />
          ) : (
            <ActivePatientsTable
              data={paginatedPatients}
              isAtRiskEnabled={atRiskEnabled}
              clinicId={clinicId}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          )}
          <div className={styles.pagination}>
            <Pagination
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              defaultPageSize={defaultPageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              pageSizeLabel={intl.formatMessage({
                id: 'patients.page-size-label',
                defaultMessage: 'Patients per page',
              })}
            />
          </div>
        </>
      )}
    </div>
  );

  function onSearch(value: string) {
    setSearch(value);
    //reset page to 1 when searching
    if (page !== 1) {
      setPage(1);
    }
  }

  function updateFilters(filters: PatientFilter) {
    const newSearchParams = new URLSearchParams(searchParams);

    for (const [filterName, filterValue] of Object.entries(filters)) {
      if (isString(filterValue) && filterValue === '') {
        newSearchParams.delete(filterName);
        continue;
      }

      newSearchParams.set(filterName, filterValue);
    }
    setSearchParams(newSearchParams);

    //reset page to 1 when searching
    if (page !== 1) {
      setPage(1);
    }
  }
}
