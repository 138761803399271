import { PropsWithChildren } from 'react';
import styles from './FormGroup.module.scss';

type Props = {
  label?: React.ReactNode;
  error?: string;
  help?: string;
};

export function FormGroup({ label, children, error, help }: PropsWithChildren<Props>) {
  return (
    <div className={styles.formGroup}>
      {label && <label className={styles.formGroupLabel}>{label}</label>}

      {children}

      <span
        className={[styles.formGroupHelp, error && styles.formGroupError].filter(Boolean).join(' ')}
        data-sentry-unmask
      >
        {error ? error : help ? help : ' '}
      </span>
    </div>
  );
}
