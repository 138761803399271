import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

import { scrollToTop } from 'src/helpers/shared';

export function useAppNavigate() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  function navigateAndScrollToTop(url: any, options?: NavigateOptions) {
    navigate(url, options);

    setTimeout(() => {
      scrollToTop();
    }, 50);
  }

  return {
    navigateAndScrollToTop,
    toClinicsPage(options?: NavigateOptions) {
      navigateAndScrollToTop('/clinics', options);
    },
    toClinicPage(clinicId?: string, options?: NavigateOptions) {
      let url = '/clinic';

      if (clinicId) {
        url += `/${clinicId}`;
      }

      navigateAndScrollToTop(url, options);
    },
    toInvitePatientPage(clinicId: string, options?: NavigateOptions) {
      navigateAndScrollToTop(`/clinic/${clinicId}/invite`, options);
    },
    toInviteProviderPage(clinicId: string, options?: NavigateOptions) {
      navigateAndScrollToTop(`/clinic/${clinicId}/invite-member`, options);
    },
    toEditProviderPage(clinicId: string, providerId: string, options?: NavigateOptions) {
      navigateAndScrollToTop(`/clinic/${clinicId}/members/${providerId}`, options);
    },
    toClinicProvidersPage(clinicId: string, options?: NavigateOptions) {
      navigateAndScrollToTop(`/clinic/${clinicId}/members`, options);
    },
    toClinicSettingsPage(clinicId: string, options?: NavigateOptions) {
      navigateAndScrollToTop(`/clinic-settings/${clinicId}`, options);
    },
    toPatientPage(clinicId: string, patientId: string, tab?: string, options?: NavigateOptions) {
      let url = `/clinic/${clinicId}/patient/${patientId}`;

      if (tab) {
        url += `#${tab}`;
      }
      navigateAndScrollToTop(url, options);
    },
    toCreateUserPage(options?: NavigateOptions) {
      navigateAndScrollToTop('/manage-users/invite', options);
    },
    toResendInvitationPage(options?: NavigateOptions) {
      navigateAndScrollToTop('/manage-users/resend-invitation', options);
    },
    toEditUserPage(providerId: string, options?: NavigateOptions) {
      navigateAndScrollToTop(`/manage-users/${providerId}/edit`, options);
    },
    toLoginPage(withRedirect = true, options?: NavigateOptions) {
      let url = '/login';

      if (location.pathname === '/login') {
        return;
      }

      if (withRedirect) {
        const searchParams = new URLSearchParams(location.search);

        if (location.pathname !== '/login') {
          searchParams.set('redirectTo', location.pathname + location.search);
        }

        url += `?${searchParams.toString()}`;
      }

      navigateAndScrollToTop(url, options);
    },
    toResetPasswordPage(options?: NavigateOptions) {
      navigateAndScrollToTop('/reset-password', options);
    },
    toTermsAndConditionsPage(options?: NavigateOptions) {
      navigateAndScrollToTop('/terms-conditions', options);
    },
    toHomePage(options?: NavigateOptions) {
      navigateAndScrollToTop('/', options);
    },
    toPreviousPage(options?: NavigateOptions) {
      navigateAndScrollToTop(-1, options);
    },
    // NOTE: Maybe we can disable the scroll to top when using tab navigation
    toAnchor(anchor: string, options?: NavigateOptions) {
      const targetUrl = `${pathname}#${anchor}`;

      navigate(targetUrl, options);
    },
    toManagePatientsPage(clinicId: string, options?: NavigateOptions) {
      navigateAndScrollToTop(`/clinic/${clinicId}/organize-patients`, options);
    },
  };
}
