import { Button, ButtonProps } from '../Button/Button';
import { IconButton, IconButtonProps } from '../IconButton/IconButton';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './Card.module.scss';

type IconButtonWithTooltip = IconButtonProps & { tooltip?: string };
type IconButtonWithRequiredTooltip = IconButtonProps & { tooltip: string };

type CardProps = React.PropsWithChildren<{
  iconButtons?: IconButtonWithTooltip[];
  button?: ButtonProps;
  title?: string | JSX.Element;
  variant?: 'default' | 'danger-border';
  className?: string;
}>;

export function Card({
  children,
  title,
  iconButtons,
  button,
  variant = 'default',
  className,
}: CardProps) {
  return (
    <div className={[styles.card, styles[variant], className].filter(Boolean).join(' ')}>
      <div className={styles.cardHeader}>
        <div className={styles.cardHeader__title}>{title}</div>
        <div className={styles.cardHeader__actions}>
          <div className={styles.cardHeader__actions__iconButtons}>
            {iconButtons?.map(({ tooltip, ...button }, index) =>
              tooltip ? (
                <IconButtonWithTooltip
                  key={index}
                  size="xs"
                  variant="icon"
                  tooltip={tooltip}
                  {...button}
                />
              ) : (
                <IconButton key={index} size="xs" variant="icon" {...button} />
              )
            )}
          </div>
          {button && <Button size="xs" variant="secondary" {...button} />}
        </div>
      </div>
      {children && <div className={styles.cardContent}>{children}</div>}
    </div>
  );
}

function IconButtonWithTooltip({ tooltip, ...button }: IconButtonWithRequiredTooltip) {
  return (
    <Tooltip text={tooltip} placement="bottom">
      <IconButton size="xs" variant="icon" {...button} />
    </Tooltip>
  );
}
