import { isPatientGroupingEnabled } from '@allurion/domain';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { TrackedPageHeader } from 'src/analytics/TrackedUI';
import { useClinicTreatements } from 'src/hooks/useClinicCustomTreatments';
import { useClinicProviders } from 'src/hooks/useClinicProviders';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { useTreatmentLabels } from 'src/hooks/useTreatmentLabels';

import styles from './ClinicPatientsFilter.module.scss';

export type PatientStatusFilter = 'all' | 'active' | 'inactive' | 'invited' | 'incomplete';
export type PatientViewFilter = 'my' | 'all';

export type PatientFilter = {
  status: PatientStatusFilter;
  treatment: string;
  view: PatientViewFilter;
  provider: string;
};
type PatientFilterKey = keyof PatientFilter;

type Props = {
  onClose: () => void;
  onFilterChange: (filter: PatientFilter) => void;
  activeFilter: PatientFilter;
};

export function ClinicPatientsFilter({ onClose, onFilterChange, activeFilter }: Props) {
  const intl = useIntl();
  const [filter, setFilter] = useState<PatientFilter>(activeFilter);
  const { clinicId } = useParams();
  const { isAdmin } = useCurrentProvider();
  const { treatmentList } = useClinicTreatements(clinicId);
  const { getTreatmentLabel } = useTreatmentLabels(clinicId);
  const { settings } = useClinicSettings(clinicId);
  const { providers } = useClinicProviders(clinicId, {
    suspense: false,
  });

  const showProviderFilter = isAdmin && isPatientGroupingEnabled(settings) && filter.view === 'all';
  const showViewFilter = isAdmin && isPatientGroupingEnabled(settings);
  const statusFilterOptions: { value: PatientStatusFilter; label: string }[] = [
    {
      value: 'all',
      label: intl.formatMessage({
        id: 'clinic-patients.status-filter.all-option',
        defaultMessage: 'All',
      }),
    },
    {
      value: 'active',
      label: intl.formatMessage({
        id: 'clinic-patients.status-filter.active-option',
        defaultMessage: 'Active',
      }),
    },
    {
      value: 'inactive',
      label: intl.formatMessage({
        id: 'clinic-patients.status-filter.inactive-option',
        defaultMessage: 'Inactive',
      }),
    },
    {
      value: 'invited',
      label: intl.formatMessage({
        id: 'clinic-patients.status-filter.invited-option',
        defaultMessage: 'Invited',
      }),
    },
    {
      value: 'incomplete',
      label: intl.formatMessage({
        id: 'clinic-patients.status-filter.incomplete-profile-option',
        defaultMessage: 'Incomplete Profile',
      }),
    },
  ];

  const treatmentOptions = [
    {
      label: intl.formatMessage({
        id: 'clinic-patients.treatment-filter.all-option',
        defaultMessage: 'All',
      }),
      value: '',
    },
    ...treatmentList.map((treatment) => ({
      label: getTreatmentLabel(treatment.custom_treatment_id),
      value: treatment.custom_treatment_id.toString(),
    })),
  ];

  const providersOptions = [
    {
      label: intl.formatMessage({
        id: 'clinic-patients.provider-filter.all-option',
        defaultMessage: 'All',
      }),
      value: '',
    },
    ...providers.map((provider) => ({
      label: `${provider.FirstName} ${provider.LastName}`,
      value: provider.ProviderID,
    })),
  ];

  return (
    <div className={styles.container}>
      <TrackedPageHeader
        title={intl.formatMessage({
          id: 'clinic-patients.filter-sidebar-title',
          defaultMessage: 'Filter',
        })}
        navButtonVariant="close"
        onNavButtonClick={onClose}
        onNavButtonClickTrackLabel="cancel-patient-note-form"
        button={{
          label: intl.formatMessage({
            id: 'clinic-patients.filter-sidebar-apply',
            defaultMessage: 'Apply',
          }),
          onClick: () => {
            onFilterChange(filter);
            onClose();
          },
          trackLabel: 'apply-patient-filter',
          size: 'sm',
        }}
      />

      <div className={styles.filters}>
        {showViewFilter && (
          <Filter
            title={intl.formatMessage({
              id: 'clinic-patients.view-filter.title',
              defaultMessage: 'View',
            })}
            options={[
              {
                label: intl.formatMessage({
                  id: 'clinic-patients.view-filter.my-patients-option',
                  defaultMessage: 'My Patients',
                }),
                value: 'my',
              },
              {
                label: intl.formatMessage({
                  id: 'clinic-patients.view-filter.all-patients-option',
                  defaultMessage: 'All Patients',
                }),
                value: 'all',
              },
            ]}
            isActive={(option) => option === filter.view}
            onClick={(option) => updateFilter('view', option)}
          />
        )}
        <Filter
          title={intl.formatMessage({
            id: 'clinic-patients.status-filter.title',
            defaultMessage: 'Status',
          })}
          options={statusFilterOptions}
          isActive={(option) => option === filter.status}
          onClick={(option) => updateFilter('status', option)}
        />
        <Filter
          title={intl.formatMessage({
            id: 'clinic-patients.treatment-filter.title',
            defaultMessage: 'Treatment',
          })}
          options={treatmentOptions}
          isActive={(option) => option === filter.treatment}
          onClick={(option) => updateFilter('treatment', option)}
        />
        {showProviderFilter && (
          <Filter
            title={intl.formatMessage({
              id: 'clinic-patients.provider-filter.title',
              defaultMessage: 'Provider',
            })}
            options={providersOptions}
            isActive={(option) => option === filter.provider}
            onClick={(option) => updateFilter('provider', option)}
          />
        )}
      </div>
    </div>
  );

  function updateFilter<T extends PatientFilterKey>(filterName: T, option: PatientFilter[T]): void {
    setFilter((prev) => {
      const next = {
        ...prev,
        [filterName]: option,
      };

      //if view is set to 'my' then provider should be empty
      if (filterName === 'view' && option === 'my') {
        next.provider = '';
      }

      return next;
    });
  }
}

function Filter<T extends string>({
  title,
  options,
  isActive,
  onClick,
}: {
  title: string;
  options: { label: string; value: T }[];
  isActive: (option: T) => boolean;
  onClick: (option: T) => void;
}) {
  return (
    <div className={styles.filter}>
      <div className={styles.filterTitle}>{title}</div>

      <ul className={styles.filterOptions}>
        {options.map((option) => (
          <li
            className={isActive(option.value) ? styles.active : ''}
            key={option.value}
            onClick={(e) => handleClick(e, option.value)}
            style={{ cursor: 'pointer' }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );

  function handleClick(e: React.MouseEvent, value: T) {
    e.preventDefault();
    e.stopPropagation();

    onClick(value);
  }
}
