import { DownloadIcon } from '@allurion/ui';
import { isError } from '@allurion/utils';
import { useState } from 'react';

import { TrackedIconButton } from 'src/analytics/TrackedUI';
import { toastError } from 'src/components/ui/toasts';
import { CircularProgress } from 'src/ui/CircularProgress';

export function useDownloadData({ exportData }: { exportData: () => Promise<any> }) {
  const [isExportingCsv, setIsExportingCsv] = useState(false);

  const onClick = async () => {
    setIsExportingCsv(true);
    try {
      await exportData();
    } catch (error: unknown) {
      toastError(isError(error) ? error?.message : error);
    }
    setIsExportingCsv(false);
  };

  return {
    onExportData: onClick,
    isExportingData: isExportingCsv,
  };
}

export function DownloadDataBtn({ exportData }: { exportData: () => Promise<any> }) {
  const { onExportData, isExportingData } = useDownloadData({ exportData });

  return (
    <TrackedIconButton
      variant="icon"
      onClick={onExportData}
      trackLabel="download-clinic-data"
      disabled={isExportingData}
      size="sm"
      icon={
        isExportingData ? (
          <CircularProgress color="currentColor" size="14px" thickness="1.5px" marginRight="0" />
        ) : (
          <DownloadIcon />
        )
      }
    />
  );
}
