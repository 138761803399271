import { Suspense } from 'react';

import { InlineLoader } from './InlineLoader';

import styles from './Sidebar.module.scss';

type Props = {
  isOpen: boolean;
  children?: React.ReactNode;
  offcanvas?: boolean;
};

export function Sidebar({ isOpen, children, offcanvas }: Props) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={[styles.sidebar, offcanvas && styles.offcanvas].filter(Boolean).join(' ')}>
      <Suspense fallback={<InlineLoader />}>{children}</Suspense>
    </div>
  );
}
