import { PatientWithStats } from '@allurion/domain';
import { InfoIcon, Popover, Table } from '@allurion/ui';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useTrackEvent } from 'src/analytics/analytics';
import { AtRiskHelpContent } from 'src/components/AtRiskHelpContent';
import { MouseContextMenu } from 'src/components/MouseContextMenu';
import { Loader } from 'src/components/ui/Loader';
import { useLocale } from 'src/hooks/useLocale';
import { PATIENT_COPY_LINK, PATIENT_NEW_TAB } from 'src/utils/constants';

import messages from './active-patients-table.messages';
import { PatientSuccessPredictorBadge } from './PatientSuccessPredictorBadge';
import { ResendInviteLink } from './ResendInviteLink';
import { usePatientFormatters } from './usePatientFormatters';

import styles from './ActivePatientsTable.module.scss';

type Props = {
  search?: string;
  data: PatientWithStats[];
  isAtRiskEnabled?: boolean;
  clinicId: string;
  sortBy: string;
  setSortBy: (field: string) => void;
};

export function ActivePatientsTable({
  clinicId,
  data,
  isAtRiskEnabled = false,
  sortBy,
  setSortBy,
}: Props) {
  const intl = useIntl();
  const { locale } = useLocale();

  const [isResendInvite, setIsResendInvite] = useState<boolean>(false);
  const { trackSorting } = useTrackEvent();
  const {
    getPatientFullNameAndAge,
    getPatientTreatmentLabel,
    getPatientCurrentWeight,
    getPatientCurrentBmi,
    getPatientWeightLossSincePlacement,
    getPatientTBWL,
    getPatientDaysInTreatment,
    getLastWeightIn,
  } = usePatientFormatters(clinicId);

  const onSortChange = (newSortBy: string) => {
    setSortBy(newSortBy);
    trackSorting({ label: 'clinic-patients-sort-by', sortBy: newSortBy });
  };

  const contextMenu = [
    {
      label: intl.formatMessage(messages.openNewTabWindow),
      value: PATIENT_NEW_TAB,
    },
    {
      label: intl.formatMessage(messages.copyPatientLink),
      value: PATIENT_COPY_LINK,
    },
  ];

  return (
    <Container>
      <Loader isLoading={isResendInvite} />
      <MouseContextMenu menuOptions={contextMenu} />
      <Table
        columns={[
          {
            title: intl.formatMessage(messages.firstName),
            field: 'name',
            formatter(_, patient) {
              return (
                <>
                  <div className={styles.patientName}>
                    <Link
                      to={`/clinic/${clinicId}/patient/${patient.uid}`}
                      className={styles.links}
                    >
                      {getPatientFullNameAndAge(patient)}
                    </Link>
                  </div>

                  <div className={styles.treatmentLabel}>
                    {getPatientTreatmentLabel(patient)}

                    <ResendInviteLink patient={patient} setIsResendInvite={setIsResendInvite} />
                  </div>
                </>
              );
            },
          },
          {
            title: (
              <>
                <span className={styles.successPredictorHelp}>
                  <Popover
                    content={
                      <div style={{ overflow: 'scroll', padding: '16px' }}>
                        <AtRiskHelpContent />
                      </div>
                    }
                    placement="bottom"
                    closeOnOutsideClick
                  >
                    <InfoIcon />
                  </Popover>
                </span>
                {intl.formatMessage(messages.atRisk)}
              </>
            ),
            field: 'at_risk_score',
            hide: !isAtRiskEnabled,
            formatter: (_, patient: any) =>
              (
                <PatientSuccessPredictorBadge
                  at_risk_display={patient?.at_risk_display}
                  at_risk_score={patient.at_risk_score}
                />
              ) || '-',
          },
          {
            title: `${intl.formatMessage(messages.currentWeight)}`,
            field: 'current_weight',
            formatter: (_, patient) => getPatientCurrentWeight(patient) || '-',
            align: 'right',
          },

          {
            title: `BMI`,
            field: 'current_bmi',
            formatter: (_, patient) => getPatientCurrentBmi(patient) || '-',
            align: 'right',
          },
          {
            title: `${intl.formatMessage(messages.weightChange)}`,
            field: 'weight_loss_since_placement',
            formatter: (_, patient) => getPatientWeightLossSincePlacement(patient) || '-',
            align: 'right',
          },
          {
            title: 'TBWL',
            field: 'perc_weight_loss_since_placement',
            formatter: (_, patient) => getPatientTBWL(patient) || '-',
            align: 'right',
          },
          {
            title: `${intl.formatMessage(messages.lastWeighInDate)}`,
            field: 'last_weigh_in',
            formatter: (_, patient) => getLastWeightIn(patient, locale) || '-',
            align: 'right',
          },
          {
            title: intl.formatMessage(messages.daysInTreatment),
            field: 'latest_treatment_start_date',
            align: 'right',
            formatter: (_, patient) => getPatientDaysInTreatment(patient),
          },
        ]}
        data={data}
        sortBy={sortBy}
        onSortChange={onSortChange}
        // onRowClick={onRowClick}
        borderStyle={(patient) =>
          patient.latest_treatment_id ? undefined : styles.missingTreatment
        }
      />
    </Container>
  );
}

const Container = styled.div`
  color: #000;
  font-size: 16px;

  .padding-left {
    padding-left: 30px;
  }

  .centered {
    width: 100%;
  }

  .offset {
    text-align: left !important;
  }
`;
