import { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { acceptPrivacyPolicy } from 'src/api/UserApi';
import { Loader } from 'src/components/ui/Loader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import { TermsAndConditionsForm } from 'src/forms/TermAndConditions/TermsAndConditionsForm';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import messages from 'src/messages/terms-and-conditions.messages';
import { Logger } from 'src/services/Logger';

const HTML_AGREEMENT =
  "<iframe style='border:1px solid black;' src='https://templates.insights.allurion.com/data_sharing_agreement/data-sharing-agreement.html' width='95%' height='1500px'></iframe>";

export default function TermsAndConditionsPage() {
  const intl = useIntl();
  const { provider } = useCurrentProvider();
  const [isLoading, setIsLoading] = useState(false);
  const { toClinicPage } = useAppNavigate();

  const submitForm = async () => {
    try {
      setIsLoading(true);
      await acceptPrivacyPolicy();
      toastSuccess(intl.formatMessage(messages.agreeTermsSuccess));
      setIsLoading(false);
      toClinicPage();
    } catch (error) {
      Logger.captureException(error);
      toastError(intl.formatMessage(messages.agreeTermsFailure));
      setIsLoading(false);
    }
  };

  const initialValues = { firstName: provider?.FirstName, lastName: provider?.LastName, date: '' };

  return (
    <Container>
      <Title>{intl.formatMessage(messages.title)}</Title>
      <Text>{intl.formatMessage(messages.agreeTerms)}</Text>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: HTML_AGREEMENT }} />
      <TermsAndConditionsForm initialValues={initialValues} onSubmit={submitForm} />
      <Loader isLoading={isLoading} />
    </Container>
  );
}

const Container = styled.div`
  color: ${({ theme }) => theme.colors.Primary};
  max-width: 900px;
  margin: 0 auto;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 200px;
  @media screen and (min-width: 940px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Title = styled.h2`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  font-size: 26px;
  margin-bottom: 25px;
`;

const Text = styled.div`
  font-size: 18px;
  margin-bottom: 45px;
`;
